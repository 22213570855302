import { default as React } from 'react';
import { ComicPage } from '../comic-page/ComicPage';
import './Home.css';

export const Home = () => {


    return (
        <div className="home">
            <ComicPage></ComicPage>
        </div>
    )

}